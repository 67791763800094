<template>
    <div class="db-container dashboard-pro-portfolios">
        <AddPortfolio ref="addPortfolioModal" />
        <div class="db-content">
            <h2 class="title">
                Portfolios
            </h2>
            <div class="controller">
                <Switcher :options="tabOptions" v-model="tab" />
            </div>
            <div class="searchAndAdd">
                <InputUnit title="" placeholder="Find Portfolios" type="text" v-model="searchPortfolio"/>
                 <button class="blue" @click="$refs.addPortfolioModal.openModal()">+ Create</button>
            </div>
            <div class="content">
                <div :key="ind" v-for="(ele, ind) in portfolios" class="port" @click="viewPortfolio(ele.product_name)">
                    <h4><img :src="loadIcon(ele.standard.toUpperCase())" alt="" class="icon">{{ ele.product_name }}</h4>
                    <p class="date">Initialized on {{ new Date(Number(ele.establish_since) * 1000) | dateFormat }}</p>
                    <p class="description">{{ ele.portfolio_series }}</p>
                    <div class="tags-container">
                        <div class="tags-mask"></div>
                        <div class="tags">
                            <span :key="`tag-${i}`" v-for="(el, i) in ele.accounts" :style="`background-image: url(${loadExchangeIcon(el.xchg)});`">{{ el.account_name }}</span>
                        </div>
                    </div>
                    <div class="statics">
                        <div>
                            <h3>{{ Number(ele.nav).toFixed(4) }}</h3>
                            <h6>Net Value</h6>
                        </div>
                        <div v-if="false">
                            <h5 class="increase">+10.23%</h5>
                            <h6>Last 7 Days</h6>
                        </div>
                    </div>
                    <div class="chart-container">
                        <NetValue :reference="25000" :records="ele.records" :styles="myStyles"/>
                    </div>
                    <h6 class="te">Total Equity: {{ ele.equity | currency(2, true) }} {{ ele.standard.toUpperCase() }}</h6>
                </div>
                <!--
                <div class="port">
                    <h4><img :src="loadIcon('USDT')" alt="" class="icon">Portfolio A</h4>
                    <p class="date">Initialized on May 25, 2022</p>
                    <p class="description">Lorem ipsum dolor sit amet, consec tetur, adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <div class="tags">
                        <span>USDT01_onebit</span>
                    </div>
                    <div class="statics">
                        <div>
                            <h3>1.0123</h3>
                            <h6>Net Value</h6>
                        </div>
                        <div>
                            <h5 class="increase">+10.23%</h5>
                            <h6>Last 7 Days</h6>
                        </div>
                    </div>
                    <div class="chart-container">
                        <NetValue :reference="25000" :records="chartData" :styles="myStyles"/>
                    </div>
                    <h6 class="te">Total Equity: 212,213.12 USDT</h6>
                </div>
                <div class="port">
                    <h4><img :src="loadIcon('USDT')" alt="" class="icon">Portfolio A</h4>
                    <p class="date">Initialized on May 25, 2022</p>
                    <p class="description">Lorem ipsum dolor sit amet, consec tetur, adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <div class="tags">
                        <span>USDT01_onebit</span>
                    </div>
                    <div class="statics">
                        <div>
                            <h3>1.0123</h3>
                            <h6>Net Value</h6>
                        </div>
                        <div>
                            <h5 class="increase">+10.23%</h5>
                            <h6>Last 7 Days</h6>
                        </div>
                    </div>
                    <div class="chart-container">
                        <NetValue :reference="25000" :records="chartData" :styles="myStyles"/>
                    </div>
                    <h6 class="te">Total Equity: 212,213.12 USDT</h6>
                </div>
                <div class="port">
                    <h4><img :src="loadIcon('USDT')" alt="" class="icon">Portfolio A</h4>
                    <p class="date">Initialized on May 25, 2022</p>
                    <p class="description">Lorem ipsum dolor sit amet, consec tetur, adi piscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <div class="tags">
                        <span>USDT01_onebit</span>
                    </div>
                    <div class="statics">
                        <div>
                            <h3>1.0123</h3>
                            <h6>Net Value</h6>
                        </div>
                        <div>
                            <h5 class="increase">+10.23%</h5>
                            <h6>Last 7 Days</h6>
                        </div>
                    </div>
                    <div class="chart-container">
                        <NetValue :reference="25000" :records="chartData" :styles="myStyles"/>
                    </div>
                    <h6 class="te">Total Equity: 212,213.12 USDT</h6>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery';
import Switcher from '@/components/Switcher';
import InputUnit from '@/components/InputUnit';
import NetValue from '@/components/NetValue';
import AddPortfolio from '@/components/AddPortfolio';
import {apiPortfoliosList, apiPortfolioRecord} from '@/apis/pro/portfolios.js';
/*
import InputUnit from '@/components/InputUnit';
import Switcher from '@/components/Switcher';
import DashboardEquitiyValue from '@/components/DashboardEquitiyValue';
import PNLStats from '@/components/PNLStats';
import APYStats from '@/components/APYStats';
*/
// import {apiNewsDetail} from '@/apis/news.js';

export default {
    name: 'Portfolios',
    beforeCreate: function() {
        document.body.className = 'noscroll';
    },
    data() {
        return {
            activePortfolios: [],
            settledPortfolios: [],
            tab: 'inOperation',
            searchPortfolio: '',
            portfolioRecords: [],
            chartData: [
                {label: new Date('2022-05-12'), data: -5000},
                {label: new Date('2022-05-13'), data: 14000},
                {label: new Date('2022-05-14'), data: 8000},
                {label: new Date('2022-05-15'), data: 25000},
                {label: new Date('2022-05-16'), data: 19000},
                {label: new Date('2022-05-17'), data: 40000},
                {label: new Date('2022-05-18'), data: 60000},
            ],
            refreshKey: 0
        }
    },
    components: {
        Switcher,
        InputUnit,
        NetValue,
        AddPortfolio,
    },
    methods: {
        loadIcon(name = ""){
            if(name == "GBP")return `https://static.bitfinex.com/images/icons/GBP-alt.svg`;
            try {
                return require(`@/assets/images/crypto-logos/${name.replace('-PERP', '')}.png`);
            } catch (e) {
                // return require(`@/assets/images/crypto-default.png`);
                return `https://static.bitfinex.com/images/icons/${name.replace('-PERP', '')}-alt.svg`;
            }
        },
        loadPortfolios(){
            apiPortfoliosList({
                filterBy: 'operating'
            }).then(res => {
                if(res.data.result){
                    this.activePortfolios = res.data.products.map(p => {
                        if (p.records.length < 1) {
                            // p.records = [{label: 0, data: 0}, {label: 0, data: 0}, {label: 0, data: 0}]
                            p.records = [0, 0, 0]
                        }
                        return p
                    })
                    // this.activePortfolios.forEach(ele => this.loadRecord(ele.product_name))
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
            apiPortfoliosList({
                filterBy: 'settled'
            }).then(res => {
                if(res.data.result){
                    this.settledPortfolios = res.data.products.map(p => {
                        if (p.records.length <= 1) {
                            // p.records = [{label: 0, data: 0}, {label: 0, data: 0}, {label: 0, data: 0}]
                            p.records = [0, 0, 0]
                        } else {
                            // p.records = p.records.map(r => {
                            //     return {label: new Date(r[1]*1000), data: r[0]}
                            // })
                            p.records = p.records.map(r => r[0])
                        }
                        return p
                    })
                    // this.settledPortfolios.forEach(ele => this.loadRecord(ele.product_name))
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        viewPortfolio(id){
            window.location = `/dashboard-pro/portfolios/${id}`
        },
        loadExchangeIcon(name = ""){
            try {
                return require(`@/assets/images/exchange-logos/${name.toLowerCase()}.svg`);
            } catch (e) {
                return require(`@/assets/images/crypto-default.png`);
            }
        },
        loadRecord(pid){
            apiPortfolioRecord({
                portfolio: pid,
                start: 0,
                hour: 8
            }).then(res => {
                if(res.data.result){
                    let records = res.data.data.length <= 1 ? [{label: 0, data: 0}, {label: 0, data: 0}, {label: 0, data: 0}] : res.data.data.map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav})).slice(-30)
                    if(this.activePortfolios.some(ele => ele.product_name == pid)){
                        this.activePortfolios.find(ele => ele.product_name == pid).records = records
                    }else if(this.settledPortfolios.some(ele => ele.product_name == pid)){
                        this.settledPortfolios.find(ele => ele.product_name == pid).records = records
                    }
                    this.$forceUpdate()
                    this.refreshKey += 1
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        /*
        findRecord(pid){
            if(this.portfolioRecords.some(ele => ele.product_name == pid)){
                return this.portfolioRecords.find(ele => ele.product_name == pid).data.map(item => ({label: new Date(Number(item.ts)*1000), data: item.nav}))
            }
            return []
        }*/
    },
    mounted() {
        this.loadPortfolios()
    },
    computed: {
        myStyles() {
          return {
            height: '100%',
            width: '100%',
            position: 'relative',
          };
        },
        tabOptions(){
            return ([{
                title: `<h2>${this.activePortfolios.length}</h2><p>In operation</p>`,
                value: 'inOperation'
            }, {
                title: `<h2>${this.settledPortfolios.length}</h2><p>Settled</p>`,
                value: 'settled'
            }, {
                title: `<h2>${this.activePortfolios.length + this.settledPortfolios.length}</h2><p>Total portfolios</p>`,
                value: 'totalPortfolios'
            }])
        },
        portfolios(){
            this.refreshKey;
            let tabInOperation = this.activePortfolios.slice()
            let tmpSettled = this.settledPortfolios.slice()
            if(this.tab == 'inOperation'){
                return tabInOperation.sort((a, b) => a.product_name.localeCompare(b.product_name)).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
                // return tabInOperation.sort((a, b) => b.establish_since - a.establish_since).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
            }else if(this.tab == 'settled'){
                return tmpSettled.sort((a, b) => a.product_name.localeCompare(b.product_name)).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
                // return tmpSettled.sort((a, b) => b.establish_since - a.establish_since).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
            }
            return (tabInOperation.concat(tmpSettled).sort((a, b) => a.product_name.localeCompare(b.product_name))).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
            //return (tabInOperation.concat(tmpSettled).sort((a, b) => b.establish_since - a.establish_since)).filter(ele => ele.product_name.toLowerCase().indexOf(this.searchPortfolio.toLowerCase())>=0)
            //return this.activePortfolios.concat(this.settledPortfolios)
        }
    },
}
</script>
